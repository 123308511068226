<template>
    <a :href="href" :target="target" :rel="rel" class="mobi_shopping_cart_icon" @click="handleIconClick">
        <svg class="w-full h-full" viewBox="0 0 24 24" width="128" height="128" :style="{ color: mobiIconColor }">
            <g>
                <path fill="currentColor" d="M7.14,19.08A1.62,1.62,0,1,1,5.52,20.7a1.62,1.62,0,0,1,1.62-1.62"></path>
                <path fill="currentColor" d="M18.78,19.08a1.62,1.62,0,1,1-1.62,1.62,1.62,1.62,0,0,1,1.62-1.62"></path>
                <path
                    fill="currentColor"
                    d="M22.28,5.79a3,3,0,0,0-2.34-.94H5.87l-.09-.9C5.52,2.27,4.54,1.68,2.94,1.68H2A1,1,0,0,0,2,3.62h1a.84.84,0,0,1,1,.69l.2,2,.71,9.38A3.06,3.06,0,0,0,8,18.43H18.93a3.12,3.12,0,0,0,2.95-2.7L23,8.18a3,3,0,0,0-.69-2.39M21,7.94,20,15.42c-.1.53-.49,1.07-1,1.07H8c-.51,0-1.24-.28-1.27-.92L6.08,6.79H19.94a1.22,1.22,0,0,1,.9.3,1.15,1.15,0,0,1,.21.86"
                ></path>
            </g>
        </svg>
        <div v-if="shoppingCartSizeText != 0" class="shopping_cart_count">{{ shoppingCartSizeText }}</div>
    </a>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useMapState } from '@jz/utils';
import { openShoppingCart } from '@/site/utils';
import { PayProduct } from '@/services';
import { funcType } from '@/components/system/TopShoppingCart/constants.js';

const { responsiveInfo, shoppingCartSize } = useMapState(['responsiveInfo', 'shoppingCartSize']);

const shoppingCartSizeText = computed(() => {
    return shoppingCartSize.value > 99 ? '99+' : shoppingCartSize.value;
});

const mobiIconColor = computed(() => {
    const headerPattern = responsiveInfo.value.pattern.header;
    if (headerPattern.mobiIconType) {
        return headerPattern.mobiIconColor;
    }
    return '';
});

const shoppingCartItem = computed(() => {
    const list = responsiveInfo.value.pattern.topShoppingCart.funcList;
    const shoppingCartItem = list.find((item) => item.type === funcType.CART);
    return shoppingCartItem;
});

const href = computed(() => {
    return shoppingCartItem.value?.jumpInfo.u ?? null;
});

const target = computed(() => {
    if (shoppingCartItem.value?.jumpInfo.u?.startsWith('javascript')) {
        return null;
    }
    return shoppingCartItem.value?.jumpInfo.target ?? null;
});

const rel = computed(() => {
    return shoppingCartItem.value?.jumpInfo.nf === 1 ? 'nofollow' : null;
});

const handleIconClick = () => {
    if (shoppingCartItem.value?.jumpInfo.jIde) {
        return;
    }
    openShoppingCart({ openCartType: 1 });
};

onMounted(() => {
    PayProduct.updateShoppingCartSize();
});
</script>

<style>
.mobi_shopping_cart_icon {
    position: relative;
    display: flex;
    width: 25px;
    height: 25px;
    color: #999999;
    cursor: pointer;
}

.jz_screen_mobi .shopping_cart_count {
    height: 16px;
    border-radius: 8px;
    font-size: 10px;
    line-height: 16px;
    min-width: 16px;
    box-sizing: border-box;
    padding: 0 4px;
}
</style>
