<template>
    <div class="module_content_detail icon_combination" :class="classes">
        <component :is="renderComponent" :module="module"></component>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { Comm } from '../../utils';
const { isNewModuleAfter20210707 } = Comm;
import firstStyleComponent from './firstStyleComponent.vue';
import secondStyleComponent from './secondStyleComponent.vue';
import thirdStyleComponent from './thirdStyleComponent.vue';

export default {
    name: 'IconCombination',
    style: 33,
    components: {
        firstStyleComponent,
        secondStyleComponent,
        thirdStyleComponent,
    },
    props: ['module'],
    computed: {
        ...mapState(['device']),
        moduleStyle() {
            return this.module.prop0;
        },
        renderComponent() {
            let component = null;
            switch (this.moduleStyle) {
                case 1:
                    component = 'firstStyleComponent';
                    break;
                case 2:
                    component = 'secondStyleComponent';
                    break;
                case 3:
                    component = 'thirdStyleComponent';
                    break;
            }
            return component;
        },
        classes() {
            const classes = [];
            if (this.isNewModuleAfter20210707) {
                classes.push('icon_combination_new_module_after_20210707');
            }
            return classes;
        },
        isNewModuleAfter20210707() {
            return isNewModuleAfter20210707(this.module);
        },
    },
    mounted() {
        Site.addQrCode('.icon_combination');
    },
};
</script>

<style>
/* 图标组合样式  start*/
.jz_screen_pc .first_style_content,
.jz_screen_pc .second_style_content,
.jz_screen_pc .third_style_content {
    padding: 1%;
}
.jz_screen_mobi .first_style_content,
.jz_screen_mobi .second_style_content,
.jz_screen_mobi .third_style_content {
    padding: 2%;
}
.icon_combination .first_style,
.icon_combination .second_style,
.icon_combination .third_style {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.icon_combination .first_style_item {
    display: block;
    width: 25%;
    text-align: center;
    box-sizing: border-box;
    margin-left: 2%;
    margin-top: 2%;
}

.first_style_content .count1.icon_module_item {
    width: 100%;
}
.first_style_content .count2.icon_module_item {
    width: 49%;
}
.first_style_content .count3.icon_module_item {
    width: 32%;
}
.first_style_content .count4.icon_module_item {
    width: 23.5%;
}
.first_style_content .count5.icon_module_item {
    width: 18.4%;
}
.first_style_content .count6.icon_module_item {
    width: 15%;
}
/* 左右间距 */
.icon_combination .first_style_content .count1:nth-child(n + 1),
.icon_combination .second_style_content .count1:nth-child(n + 1),
.icon_combination .third_style_content .count1:nth-child(n + 1) {
    margin-left: 0;
}

.icon_combination .first_style_content .count2:nth-child(2n + 1),
.icon_combination .second_style_content .count2:nth-child(2n + 1),
.icon_combination .third_style_content .count2:nth-child(2n + 1) {
    margin-left: 0;
}

.icon_combination .first_style_content .count3:nth-child(3n + 1),
.icon_combination .second_style_content .count3:nth-child(3n + 1),
.icon_combination .third_style_content .count3:nth-child(3n + 1) {
    margin-left: 0;
}

.icon_combination .first_style_content .count4:nth-child(4n + 1),
.icon_combination .second_style_content .count4:nth-child(4n + 1),
.icon_combination .third_style_content .count4:nth-child(4n + 1) {
    margin-left: 0;
}

.icon_combination .first_style_content .count5:nth-child(5n + 1),
.icon_combination .second_style_content .count5:nth-child(5n + 1),
.icon_combination .third_style_content .count5:nth-child(5n + 1) {
    margin-left: 0;
}

.icon_combination .first_style_content .count6:nth-child(6n + 1),
.icon_combination .second_style_content .count6:nth-child(6n + 1),
.icon_combination .third_style_content .count6:nth-child(6n + 1) {
    margin-left: 0;
}

/* 上下间距 */
.icon_combination .first_style_content .count1:nth-child(-n + 1),
.icon_combination .second_style_content .count1:nth-child(-n + 1),
.icon_combination .third_style_content .count1:nth-child(-n + 1) {
    margin-top: 0;
}

.icon_combination .first_style_content .count2:nth-child(-n + 2),
.icon_combination .second_style_content .count2:nth-child(-n + 2),
.icon_combination .third_style_content .count2:nth-child(-n + 2) {
    margin-top: 0;
}

.icon_combination .first_style_content .count3:nth-child(-n + 3),
.icon_combination .second_style_content .count3:nth-child(-n + 3),
.icon_combination .third_style_content .count3:nth-child(-n + 3) {
    margin-top: 0;
}

.icon_combination .first_style_content .count4:nth-child(-n + 4),
.icon_combination .second_style_content .count4:nth-child(-n + 4),
.icon_combination .third_style_content .count4:nth-child(-n + 4) {
    margin-top: 0;
}

.icon_combination .first_style_content .count5:nth-child(-n + 5),
.icon_combination .second_style_content .count5:nth-child(-n + 5),
.icon_combination .third_style_content .count5:nth-child(-n + 5) {
    margin-top: 0;
}

.icon_combination .first_style_content .count6:nth-child(-n + 6),
.icon_combination .second_style_content .count6:nth-child(-n + 6),
.icon_combination .third_style_content .count6:nth-child(-n + 6) {
    margin-top: 0;
}

.icon_combination .first_style_item .item_img {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
}
.icon_combination .imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon_combination .first_style_item .imgContainer {
    margin: 0 auto;
    width: 20%;
}
.icon_combination .first_style_item .imgAlignLeft .imgContainer {
    margin: 0;
}
.icon_combination .first_style_item_content {
    padding: 15%;
    min-width: 50%;
}
.icon_combination .first_style_item .item_title {
    font-size: 16px;
    color: #333;
    margin-top: 30px;
    line-height: 22px;
    word-break: break-word;
    -webkit-background-clip: text;
}
.icon_combination .first_style_item .item_desc {
    font-size: 14px;
    color: #999;
    margin-top: 12px;
    line-height: 22px;
    word-break: break-word;
    -webkit-background-clip: text;
}

.icon_combination .second_style_item {
    display: flex;
    width: 25%;
    box-sizing: border-box;
    margin-left: 2%;
    margin-top: 2%;
    border-radius: 2px;
}
.icon_combination .second_style_item .second_style_item_content {
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 9% 6%;
    text-align: left;
}

.icon_combination .second_style_content .count1.icon_module_item {
    width: 100%;
}
.icon_combination .second_style_content .count2.icon_module_item {
    width: 49%;
}
.icon_combination .second_style_content .count3.icon_module_item {
    width: 32%;
}
.icon_combination .second_style_content .count4.icon_module_item {
    width: 23.5%;
}
.icon_combination .second_style_content .count5.icon_module_item {
    width: 18.4%;
}
.icon_combination .second_style_content .count6.icon_module_item {
    width: 15%;
}

.icon_combination .second_style_item .second_style_item_left {
    text-align: center;
    flex: 1;
    font-size: 0px;
}

.icon_combination .second_style_item .second_style_item_right {
    padding-left: 14px;
    width: 76%;
}
.icon_combination .second_style_item .item_title {
    font-size: 16px;
    color: #333;
    line-height: 22px;
    word-break: break-word;
    -webkit-background-clip: text;
}
.icon_combination .second_style_item .item_desc {
    font-size: 14px;
    color: #999;
    margin-top: 12px;
    line-height: 22px;
    word-break: break-word;
    -webkit-background-clip: text;
}
.icon_combination .second_style_item .imgAlignTop {
    align-items: initial;
}

.jz_screen_mobi .icon_combination .second_style_item .second_style_item_right {
    width: 84%;
}
.jz_screen_mobi .icon_combination .second_style_item_content {
    padding: 6.3% 8.09%;
}
.jz_screen_mobi .icon_combination .second_style_item {
    width: 100%;
    text-align: left;
    box-sizing: border-box;
    margin-left: 2%;
    padding: 0;
}

.icon_combination .third_style_item {
    display: block;
    width: 22.975%;
    box-sizing: border-box;
    margin-left: 2.7%;
    background-color: #f2f2f2;
    margin-top: 2%;
}

.icon_combination .third_style_item_content {
    padding: 12.5% 15.6%;
    border-radius: 2px;
}

.icon_combination .third_style .count1.icon_module_item {
    width: 100%;
}
.icon_combination .third_style .count2.icon_module_item {
    width: 48.65%;
}
.icon_combination .third_style .count3.icon_module_item {
    width: 31.53%;
}
.icon_combination .third_style .count4.icon_module_item {
    width: 22.975%;
}
.icon_combination .third_style .count5.icon_module_item {
    width: 17.84%;
}
.icon_combination .third_style .count6.icon_module_item {
    width: 14.416%;
}

.icon_combination .third_style_item .imgAlignCenter {
    text-align: center;
}
.icon_combination .third_style_item .imgContainer {
    width: 20%;
}
.icon_combination .third_style_item .item_title {
    font-size: 16px;
    color: #333;
    margin-top: 24px;
    line-height: 22px;
    word-break: break-word;
    -webkit-background-clip: text;
}
.icon_combination .third_style_item .item_desc {
    font-size: 14px;
    color: #999;
    margin-top: 10px;
    line-height: 22px;
    word-break: break-word;
    -webkit-background-clip: text;
}
.icon_combination .third_style_item .imgAlignCenter .imgContainer {
    margin: 0 auto;
}
.icon_combination .icon_item_container.imgAlignLeft {
    text-align: left;
}

.jz_screen_mobi .third_style_content {
    padding: 2%;
}
.jz_screen_mobi .icon_combination .third_style_item {
    margin-top: 4%;
}
.jz_screen_mobi .icon_combination .third_style_item {
    width: 48%;
    margin-left: 4%;
    padding: 0;
}

.jz_screen_mobi .icon_combination .third_style_item_content {
    padding: 8.4%;
}

.jz_screen_mobi .icon_combination .third_style .count1.icon_module_item {
    width: 100%;
}
.jz_screen_mobi .icon_combination .third_style .count2.icon_module_item {
    width: 48%;
}
.jz_screen_mobi .icon_combination .third_style .count3.icon_module_item {
    width: 30.66%;
}
.jz_screen_mobi .icon_combination .third_style .count4.icon_module_item {
    width: 22%;
}
.jz_screen_mobi .icon_combination .third_style .count5.icon_module_item {
    width: 16.8%;
}
.jz_screen_mobi .icon_combination .third_style .count6.icon_module_item {
    width: 13.33%;
}

.jz_screen_mobi .icon_combination .third_style_item .item_title,
.jz_screen_mobi .icon_combination .first_style_item .item_title {
    margin-top: 14px;
}
.jz_screen_mobi .icon_combination .third_style_item .imgContainer,
.jz_screen_mobi .icon_combination .first_style_item .imgContainer {
    width: 26%;
}

.icon_combination .iconBgShadow {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
}
/*.icon_combination .iconImgWrap { position: relative; }*/

.icon_combination_new_module_after_20210707 {
    .icon_item_container .item_title,
    .icon_item_container .item_desc {
        line-height: 1.5;
    }
}
/* 图标组合样式 end*/
.icon_combination .c__imgeffects_wrap {
    overflow: visible;
}
</style>
