<template>
    <div class="search_result_product">
        <div v-if="other.showResultType" class="search_result_title jz_theme_font_color">{{
            LS.searchResultProduct
        }}</div>
        <div
            v-for="item in list"
            :key="uniqueId(item.pid)"
            class="exhibition_show_product result_calc_item"
            :class="{ exhibition_show_product_nopic: noPicStyle }"
            :style="productStyle"
        >
            <a
                :href="setQueryString(item.url, 'fromColId', colId)"
                target="_blank"
                :class="{ border_bottom_transition: noPicStyle }"
                class="exhibition_show_product_wrap jz_hover jz_theme_font_hover_color jz_theme_border_hover_color"
                :onclick="createOnclickStr(item)"
            >
                <div v-if="!noPicStyle">
                    <div class="exhibition_product_pic_wrap result_pic_item_padding--bottom" :style="productSizeStyle">
                        <ImageComponent
                            v-lazyload2="{
                                id: module.id,
                                src: item.picPath,
                                naturalWidth: item.picWidth,
                                naturalHeight: item.picHeight,
                            }"
                            :src="item.picPath"
                            :webp="webpOptOpen"
                            wrap-class="exhibition_product_pic_wrap"
                            :wrap-style="productImgSize"
                            class="exhibition_product_pic_item"
                            quality="700"
                            :style="picItemStyle"
                        >
                        </ImageComponent>
                        <div v-if="getIsShowPayKnowTag(item)" class="pay_know_tag">{{ getPayKnowTag(item) }}</div>
                    </div>
                    <div class="exhibition_prop">
                        <div class="exhibition_product_name_wrap">
                            <span
                                class="result_highlight_node exhibition_product_name jz_hover jz_theme_font_hover_color"
                            >
                                {{ item.name }}
                            </span>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="result_highlight_node exhibition_product_name">{{ item.name }}</div>
                    <div v-if="other.showClassification && someProductHasCategory" class="category-wrapper">
                        <a
                            v-for="groupItem in item.groups"
                            v-if="groupItem"
                            class="category-item"
                            :href="setQueryString(groupItem.url, 'fromColId', colId)"
                            target="_blank"
                        >
                            {{ groupItem.name }}
                        </a>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { getIsOpenRemoveUrlArg } from '@/site/shared/cookie/index.js';
import { setQueryString, Comm } from '../../utils';
import { PayKnowProduct } from '@/services';
import { ImageComponent } from '@jz/biz-shared';
const { imageSettingtMixin, createUrlArgsOnclickStr } = Comm;
export default {
    name: 'UnitSearchResultProduct',
    mixins: [imageSettingtMixin],
    inject: ['module'],
    components: {
        ImageComponent,
    },
    props: {
        list: {
            type: Array,
            default: () => [],
        },
        other: {
            type: Object,
            default: () => ({}),
            require: true,
        },
    },
    data() {
        return {
            moduleId: 'search_product',
        };
    },
    computed: {
        ...mapState(['device', 'manageMode', 'colId', 'LS', 'isMaterialLazyLoad', 'webpOptOpen']),
        ...mapGetters(['hashRemoved']),
        isMobi() {
            return this.device === 'mobi';
        },
        pageCount() {
            const total = this.product.total || 0;
            return total % this.other.pageSize === 0
                ? total / this.other.pageSize
                : Math.ceil(total / this.other.pageSize);
        },
        noPicStyle() {
            return this.module.prop0 === 0;
        },
        model() {
            return this._mixin_model;
        },
        productStyle() {
            if (this.noPicStyle || this.isMobi) {
                return {};
            }
            return this._mixin_itemStyle;
        },
        productSizeStyle() {
            if (this.noPicStyle || this.isMobi) {
                return {};
            }
            return this._mixin_itemSizeStyle;
        },
        // picItemStyle() {
        //     return this._mixin_picStyle;
        // },
        someProductHasCategory() {
            return this.list.some((item) => Array.isArray(item.groups) && item.groups.length > 0);
        },
        productImgSize() {
            var style = {};
            if (this.module.prop0 != 2) {
                if (this.model.pht == 1) {
                    // 图片高度
                    style.paddingBottom = Math.floor(this.model.ph * 10000) / 100 + '%';
                } else if (this.isMobi && this.model.pht == 0 && this.pcPrivatePattern.pht == 1) {
                    style['padding-bottom'] = Math.floor(this.pcPrivatePattern.ph * 10000) / 100 + '%';
                }
            } else {
                if (this.model.pst == 1) {
                    // 样式三 图片高度
                    style.width = Math.floor(this.model.psw * 10000) / 100 + '%';
                    style.paddingBottom = Math.floor(this.model.psh * 10000) / 100 + '%';
                }
            }

            return style;
        },
        curPpst() {
            return this.module.privatePattern.pc.psy;
        },
        picItemStyle() {
            var style = {};
            switch (this.curPpst) {
                case 1:
                    style['object-fit'] = 'cover';
                    style['object-position'] = 'center';
                    break;
                case 2:
                    style['object-fit'] = 'none';
                    style['object-position'] = 'center';
                    break;
                case 3:
                    style['object-fit'] = 'contain';
                    style['object-position'] = 'center';
                    break;
            }
            return style;
        },
    },
    methods: {
        getPayKnowTag(item) {
            return this.$store.getters.getProductPayKnowTypeText(item.payContentType);
        },
        getIsShowPayKnowTag(item) {
            return (
                this.module.openKnowPayment && PayKnowProduct.isPayKnowProduct(item) && this.module.privatePattern.pc.ct
            );
        },
        setQueryString(url, key, val) {
            return setQueryString(url, key, val);
        },
        uniqueId(id) {
            return `${id}` + this.module.prop0;
        },
        createOnclickStr(item) {
            if (this.$isServer) {
                return null;
            }
            return createUrlArgsOnclickStr(item.pdReqArgs, getIsOpenRemoveUrlArg(), this.hashRemoved);
        },
        genPicItemStyle(item) {
            let style = {};

            if (this.isMaterialLazyLoad) {
                style['background-image'] = `url('${item.picPath}')`;
            }

            return { ...this.picItemStyle, ...style };
        },
    },
};
</script>

<style>
.result_pic_background_default {
    background: no-repeat center;
    background-size: cover;
}
</style>
