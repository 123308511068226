<template>
    <div>
        <html-renderer class="cookie_policy_html" :html="cookiePolicyContent"></html-renderer>
    </div>
</template>

<script>
import HtmlRenderer from '@/components/htmlRenderer/index.vue';
export default {
    props: {
        module: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        HtmlRenderer,
    },
    computed: {
        cookiePolicyContent() {
            const regex = new RegExp('(?<! )&nbsp;(?! )', 'g');
            return this.module.cookiePolicyContent?.replace(regex, ' ') || '';
        },
    },
};
</script>

<style>
.cookie_policy_html {
    overflow: hidden;
    word-break: break-word;
}

.jz_screen_mobi .cookie_policy_html {
    padding: 0 15px;
}
</style>
