<template>
    <main class="m_member_findpwd_main" :class="device === 'mobi' ? 'm_member_findpwd_main--mobi' : ''">
        <header
            v-if="device === 'mobi'"
            style="
                position: relative;
                height: 14.667vw;
                line-height: 14.667vw;
                text-align: center;
                border-bottom: 1px solid #f0f0f0;
                color: #333;
                font-size: 4vw;
            "
        >
            <i
                class="faisco-icons-S000107"
                style="
                    position: absolute;
                    left: 2vw;
                    padding: 2vw;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                "
                @click="backClick"
            ></i>
            <span>
                {{ findPwdLoc }}
            </span>
        </header>
        <div v-show="showFindPwdNext" class="m_member_findpwd_item J_memberFindpwdStepOne">
            <div class="m_member_findpwd_box">
                <input
                    v-model="findPwdValue"
                    class="m_member_findpwd_input J_memberfindpwdAcct"
                    :placeholder="acctPlaceHolder"
                />
            </div>
        </div>
        <div v-show="showFindPwdNext" class="m_member_findpwd_item J_memberFindpwdStepOne">
            <div class="m_member_findpwd_box">
                <input
                    v-model="findpwdCodeValue"
                    class="m_member_findpwd_input J_memberFindpwdCode"
                    :placeholder="codePlaceHolder"
                />
                <input v-model="findPwdSignValue" class="J_memberFindpwdSign" style="display: none" />
                <div class="m_member_findpwd_acquire_code J_memberFindpwdAcquireCode" @click="findpwdAcquireCodeClick">
                    {{ acquireCode }}
                </div>
            </div>
        </div>
        <button
            v-show="showFindPwdNext"
            type="button"
            class="m_member_findpwd_btn J_memberFindpwdNext jz_theme_bg_color J_memberFindpwdStepOne"
            @click="findpwdNextClick"
        >
            {{ nextText }}
        </button>
        <div v-show="showFindpwdStepTwo" class="m_member_findpwd_item J_memberFindpwdStepTwo">
            <div class="m_member_findpwd_box">
                <input
                    v-model="findpwdPwdValue"
                    class="m_member_findpwd_input J_memberfindpwdPwd"
                    :placeholder="enterNewPwd"
                />
            </div>
        </div>
        <div v-show="showFindpwdStepTwo" class="m_member_findpwd_item J_memberFindpwdStepTwo">
            <div class="m_member_findpwd_box">
                <input
                    v-model="findpwdPwdAgainValue"
                    class="m_member_findpwd_input J_memberfindpwdPwdAgain"
                    :placeholder="enterNewPwdAgain"
                />
            </div>
        </div>
        <button
            v-show="showFindpwdStepTwo"
            type="button"
            class="m_member_findpwd_btn J_memberFindpwdNextTwo jz_theme_bg_color J_memberFindpwdStepTwo"
            @click="findpwdNextTwoClick"
        >
            {{ nextText }}
        </button>
        <div v-show="showFindpwdStepLast" class="J_memberFindpwdStepLast">
            <button
                type="button"
                class="m_member_findpwd_btn jz_theme_bg_color J_memberfindpwdLogin"
                @click="loginClick"
            >
                {{ login }}
            </button>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex';

import { getFindPwdInfo } from '@/api/findPwd/index';
import { warningMessage, successMessage } from '@/site/shared/dialog/index.js';
import {
    sendMemberPwdEmail,
    sendMemberPwdMobileCode,
    setMemberPwdGeneral,
    checkMobileCodeGeneral,
} from '@/api/member/index.js';
export default {
    name: 'findPwdPopup',
    data() {
        return {
            findPwByMailOpen: false,
            findPwByMobileOpen: false,
            acquireCodeDisabled: false,
            findPwdValue: '',
            findPwdSignValue: '',
            acquireCode: '',
            findpwdCodeValue: '',
            memberFindpwdNextDisabled: false,
            showFindpwdStepTwo: false,
            showFindPwdNext: true,
            memId: '',
            findpwdPwdValue: '',
            findpwdPwdAgainValue: '',
            showFindpwdStepLast: false,
        };
    },
    computed: {
        ...mapState(['device']),
        LS() {
            return window.LS;
        },
        acctPlaceHolder() {
            return this.findPwByMobileOpen && this.findPwByMailOpen
                ? this.LS.memberDialogPlzEnterBoth
                : this.findPwByMobileOpen
                ? this.LS.memberDialogPlzEnterMobile
                : this.findPwByMailOpen
                ? this.LS.memberDialogPlzEnterMail
                : '';
        },
        codePlaceHolder() {
            return this.LS.mobi_EnterVerificationCode;
        },
        acquireCodeText() {
            return this.LS.mobi_getMobileCode;
        },
        nextText() {
            return this.LS.mobi_memberDialogNextStep;
        },
        enterNewPwd() {
            return this.LS.mobi_memberProfile_password;
        },
        enterNewPwdAgain() {
            return this.LS.mobi_memberSignup_retypePasswordV3;
        },
        login() {
            return this.LS.memberLogin;
        },
        findPwdLoc() {
            return this.LS.mobi_memberDialogFwdStepOneTitle;
        },
    },
    created() {
        this.acquireCode = this.acquireCodeText;
        this.getFindPwdInfo();
    },
    methods: {
        async getFindPwdInfo() {
            const result = await getFindPwdInfo();
            if (result.success) {
                this.findPwByMailOpen = result.findPwByMailOpen;
                this.findPwByMobileOpen = result.findPwByMobileOpen;
            } else {
                warningMessage(LS.systemError, true);
            }
        },
        findpwdAcquireCodeClick() {
            if (this.acquireCodeDisabled) {
                return;
            }
            if (!this.findPwdValue) {
                warningMessage(this.acctPlaceHolder, true);
                return;
            }
            if (this.findPwByMailOpen && !this.findPwByMobileOpen && !Fai.isEmail(this.findPwdValue)) {
                warningMessage(this.LS.memberDialogPleaseSureMail, true);
                return;
            }

            if (!this.findPwByMailOpen && this.findPwByMobileOpen && !Fai.isMobile(this.findPwdValue)) {
                warningMessage(this.LS.memberDialogPleaseSurePhone, true);
                return;
            }

            if (
                this.findPwByMailOpen &&
                this.findPwByMobileOpen &&
                !Fai.isEmail(this.findPwdValue) &&
                !Fai.isMobile(this.findPwdValue)
            ) {
                warningMessage(this.LS.memberDialogPleaseSurePhoneOrMail, true);
                return;
            }

            if (Fai.isEmail(this.findPwdValue)) {
                new Promise((resolve, reject) => {
                    sendMemberPwdEmail({
                        memName: this.findPwdValue,
                        new: true,
                    })
                        .then((data) => {
                            data.success ? resolve(data) : reject(data);
                        })
                        .catch(reject);
                })
                    .then((data) => {
                        successMessage(this.LS.memberDialogSendMailSucess, true);
                        this.findPwdSignValue = data.emailCodeSign;
                        this.acquireCodeCountdown();
                    })
                    .catch((data) => {
                        if (!data) {
                            warningMessage(this.LS.memberDialogError, true);
                        } else {
                            if (data.aliasNotF) {
                                warningMessage(this.LS.memberDialogAliasNotFound, true);
                            } else if (data.notFound) {
                                warningMessage(this.LS.memberDialogNotFound, true);
                            } else if (data.smtpErr) {
                                warningMessage(this.LS.smtpErr, true);
                            } else {
                                warningMessage(data.msg, true);
                            }
                        }
                    });
            } else if (Fai.isMobile(this.findPwdValue)) {
                new Promise((resolve, reject) => {
                    sendMemberPwdMobileCode({
                        memName: this.findPwdValue,
                        new: true,
                    })
                        .then((data) => {
                            data.success ? resolve(data) : reject(data);
                        })
                        .catch(reject);
                })
                    .then((data) => {
                        successMessage(this.LS.memberDialogSendMobileCode, true);
                        this.findPwdSignValue = data.mobileCodeSign;
                        this.acquireCodeCountdown();
                    })
                    .catch((data) => {
                        this.acquireCodeDisabled = false;
                        if (!data) {
                            warningMessage(this.LS.memberDialogError, true);
                        } else {
                            if (data.argErr) {
                                warningMessage(this.LS.memberDialogSendMobileClose, true);
                            } else if (data.apiKeyNotFound) {
                                warningMessage(this.LS.memberDialogYunPianErr, true);
                            } else if (data.tplNotFound) {
                                warningMessage(this.LS.memberDialogMobileTplErr, true);
                            } else if (data.notFound) {
                                warningMessage(this.LS.memberDialogNotFound, true);
                            } else if (data.limitOne) {
                                warningMessage(this.LS.getMobileOneMin, true);
                            } else if (data.limitTwo) {
                                warningMessage(this.LS.getMobileHalfHour, true);
                            } else if (data.noMoney) {
                                warningMessage(this.LS.memberDialogMobileMoneyErr, true);
                            } else if (data.mobileErr) {
                                warningMessage(this.LS.memberDialogSendMobileCodeErr, true);
                            } else if (data.sendLimit) {
                                warningMessage(this.LS.memberDialogSendMobileCodeLimit, true);
                            } else if (data.mobileSysErr) {
                                warningMessage(this.LS.memberDialogSendMobileSysErr, true);
                            } else if (data.tplNationErr) {
                                warningMessage(this.LS.mobileNationTplErr, true);
                            } else if (data.systemErr) {
                                warningMessage(this.LS.systemError, true);
                            } else {
                                warningMessage(data.msg, true);
                            }
                        }
                    });
            }
        },
        findpwdNextClick() {
            if (!this.findPwdSignValue) {
                warningMessage(LS.memberDialogGetCodeFirst, true);
                return;
            }
            if (!this.findpwdCodeValue) {
                warningMessage(LS.msgBoardInputValidateCode, true);
                return;
            }
            if (!this.findPwdValue) {
                return;
            }

            let mode = '';
            if (Fai.isEmail(this.findPwdValue)) {
                mode = 'mail';
            } else if (Fai.isMobile(this.findPwdValue)) {
                mode = 'mobile';
            }

            this.memberFindpwdNextDisabled = true;
            new Promise((resolve, reject) => {
                checkMobileCodeGeneral({
                    memCode: this.findpwdCodeValue,
                    memCodeSign: this.findPwdSignValue,
                    acquirer: this.findPwdValue,
                    mode,
                })
                    .then((data) => {
                        data.success ? resolve(data) : reject(data);
                    })
                    .catch(reject);
            })
                .then((data) => {
                    this.showFindpwdStepTwo = true;
                    this.showFindPwdNext = false;
                    this.memId = data.memId;
                    this.memberFindpwdNextDisabled = false;
                })
                .catch((data) => {
                    this.memberFindpwdNextDisabled = false;
                    if (!data) {
                        warningMessage(this.LS.memberDialogError, true);
                    } else {
                        if (data.notFound) {
                            warningMessage(this.LS.memberDialogNotFound, true);
                        } else if (data.rt == 1) {
                            warningMessage(this.LS.memberDialogCodeMailFailure, true);
                        }
                    }
                });
        },
        findpwdNextTwoClick() {
            const memberfindpwdPwd = this.findpwdPwdValue;
            if (!memberfindpwdPwd) {
                warningMessage(LS.memberDialogPleaseEnterPwd, true);
                return;
            }
            const memberfindpwdPwdAgain = this.findpwdPwdAgainValue;
            if (!memberfindpwdPwdAgain) {
                warningMessage(LS.memberDialogPleaseEnterPwd2, true);
                return;
            }
            if (memberfindpwdPwdAgain !== memberfindpwdPwd) {
                warningMessage(LS.memberDialogPwdDifToPwd2, true);
                return;
            }
            if (memberfindpwdPwd.length < 4 || memberfindpwdPwd.length > 20) {
                warningMessage(Fai.format(LS.memberDialogPwdLimit, 4, 20), true);
                return;
            }
            const memId = this.memId;
            setMemberPwdGeneral({
                newPw: $.md5(memberfindpwdPwd),
                memId: Fai.encodeUrl(memId),
            })
                .then((data) => {
                    if (data.success) {
                        this.showFindpwdStepTwo = false;
                        this.showFindpwdStepLast = true;
                        successMessage(LS.memberDialogPwdModifySucc, true);
                    } else {
                        if (data.notFound) {
                            warningMessage(LS.memberDialogNotFound, true);
                        } else {
                            warningMessage(LS.js_argsError, true);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    warningMessage(LS.systemError, true);
                });
        },
        loginClick() {
            window.location.href = '/login.jsp';
        },
        acquireCodeCountdown() {
            this.acquireCodeDisabled = true;

            let downTime = 60;
            const timer = setInterval(() => {
                downTime--;
                this.acquireCode = Fai.format(LS.reacquireMemCode, downTime);
                if (downTime < 1) {
                    this.acquireCodeDisabled = false;
                    this.acquireCode = this.acquireCodeText;
                    clearInterval(timer);
                }
            }, 1000);
        },
        backClick() {
            $('.m_member_findpwd_main').remove();
            this.$emit('on-back');
        },
    },
};
</script>

<style>
.m_member_findpwd_item {
    max-width: 330px;
    height: 58px;
    margin: 12px auto 0;
}

.m_member_findpwd_box {
    position: relative;
    height: 100%;
}

.m_member_findpwd_box:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
}

.m_member_findpwd_input {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    outline: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.J_memberFindpwdCode {
    max-width: 50%;
}

.m_member_findpwd_acquire_code {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
    color: #333;
}

.m_member_findpwd_btn {
    display: block;
    width: 100%;
    max-width: 240px;
    height: 40px;
    border: 0;
    margin: 35px auto 0;
    border-radius: 22px;
    color: #fff;
    outline: 0;
    cursor: pointer;
}

.m_member_findpwd_main {
    overflow: hidden;
    margin-top: 23px;
}

.m_member_findpwd_main--mobi {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9032;
    background-color: #fff;
    margin-top: 0;
}
</style>
